import { Action, createReducer, on } from '@ngrx/store';

import { DynamicComponent } from '@priva/components/dynamic';

import { AppActions } from '../actions';
import { AppState, initialAppState } from '../app.state';

const reducer = createReducer<AppState>(
    initialAppState,

    on(AppActions.openDialog, (state: AppState, action): AppState => {
        const activeDialogs = [
            ...state.activeDialogs.filter((d) => d.component !== action.dialog.component),
            action.dialog,
        ];
        return { ...state, activeDialogs };
    }),

    on(AppActions.closeDialog, (state: AppState, _action): AppState => {
        const activeDialogs: DynamicComponent[] = [...state.activeDialogs];
        activeDialogs.pop();
        return { ...state, activeDialogs };
    }),

    on(AppActions.openPanel, (state: AppState, action): AppState => {
        return {
            ...state,
            activePanel: action.panel,
        };
    }),

    on(AppActions.closePanel, (state: AppState, _action): AppState => {
        return {
            ...state,
            activePanel: undefined,
        };
    }),

    on(AppActions.resetState, (_state, _action): AppState => {
        return {
            ...initialAppState,
        };
    }),

    on(AppActions.showToaster, (state: AppState, { notification }): AppState => {
        return {
            ...state,
            notification,
        };
    }),

    on(AppActions.removeToaster, (state) => {
        return {
            ...state,
            notification: undefined,
        };
    }),
    on(AppActions.loadCurrentUser, (state: AppState): AppState => {
        return {
            ...state,
            currentUser: null,
        };
    }),

    on(AppActions.updateCurrentUser, (state: AppState, { currentUser }): AppState => {
        return {
            ...state,
            currentUser,
        };
    }),
    on(AppActions.loadCurrentUserError, (state: AppState): AppState => {
        return {
            ...state,
            currentUser: null,
        };
    }),
    on(AppActions.loadTenantUserList, (state: AppState): AppState => {
        return {
            ...state,
            tenantUsers: [],
            isTenantUsersLoaded: false,
        };
    }),
    on(AppActions.loadTenantUserListSuccess, (state: AppState, { tenantUsers }): AppState => {
        const sortedTenantUsers = [...tenantUsers.sort((a, b) => a.name.localeCompare(b.name))];
        return {
            ...state,
            tenantUsers: sortedTenantUsers,
            isTenantUsersLoaded: true,
        };
    }),
    on(AppActions.loadTenantUserListError, (state: AppState): AppState => {
        return {
            ...state,
            tenantUsers: [],
            isTenantUsersLoaded: true,
        };
    }),
    on(AppActions.showThrobber, (state: AppState, action): AppState => {
        const throbbers = { ...state.throbbers };
        throbbers[action.selectorClass] = action.loadingMessage as string;

        return {
            ...state,
            throbbers,
        };
    }),
    on(AppActions.clearThrobber, (state, action): AppState => {
        const throbbers = { ...state.throbbers };
        delete throbbers[action.selectorClass];

        return {
            ...state,
            throbbers,
        };
    }),
    on(AppActions.clearAllThrobbers, (state: AppState, _action: Action): AppState => {
        return {
            ...state,
            throbbers: {},
        };
    }),

    on(AppActions.showPageLoader, (state: AppState): AppState => {
        return {
            ...state,
            showPageLoader: true,
        };
    }),

    on(AppActions.hidePageLoader, (state: AppState): AppState => {
        return {
            ...state,
            showPageLoader: false,
        };
    }),
);

export function appReducer(state: AppState | undefined, action: Action) {
    return reducer(state, action);
}
