import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { AppShellAreaService, AppShellAreaType } from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';

import { ErrorHandlerService } from './core/error/error-handling.service';
import { AppState } from './state';
import { AppFacade } from './state/facade';
import { selectShowPageLoader } from './state/selectors/app.selectors';

@Component({
    selector: 'analytics-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;
    public hasLoadedLanguage = false;

    private unsubscribe$: Subject<void> = new Subject();
    public throbbers$: Observable<any>;
    public showLoaderOnNavigation = signal(true);
    public selectPageLoaderStatus = this.appStore.selectSignal(selectShowPageLoader);
    public showLoader = computed(() => this.showLoaderOnNavigation() || this.selectPageLoaderStatus());

    constructor(
        private notificationService: PrivaNotificationsService,
        private appShellAreaService: AppShellAreaService,
        private errorHandler: ErrorHandlerService,
        private appFacade: AppFacade,
        private localizationService: PrivaLocalizationService,
        private router: Router,
        private appStore: Store<AppState>,
    ) {
        this.throbbers$ = this.appFacade.throbbers$;

        this.router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.showLoaderOnNavigation.set(true);
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.showLoaderOnNavigation.set(false);
            }
        });
    }

    public ngOnInit() {
        /* TODO: remove after localization service update - should load with default language*/
        this.localizationService.language.pipe(first()).subscribe(() => {
            this.hasLoadedLanguage = true;
        });

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));

        // Initialize ErrorHandler
        this.errorHandler.initialize();
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public dispatchAction(action: Action) {
        this.appFacade.dispatchAction(action);
    }
}
